import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "requests",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#requests",
        "aria-label": "requests permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requests`}</h1>
    <p>{`Request attributes have been listed in below tables as mandatory, optional, conditional or blank. If the field is blank for a given scenario, do not send it in requests - even with null values.`}</p>
    <p><strong parentName="p">{`Field condition table`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mandatory`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conditional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Blank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not applicable, do not set in request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Special conditions`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for Medicare bulk billed claims`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for Medicare patient claims`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only applicable for referred services`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><strong parentName="p">{`Method and API endpoint:`}</strong>{` `}<Highlight backgroundColour="green" mdxType="Highlight">{`POST`}</Highlight>{`  `}<inlineCode parentName="p">{`{baseurl}/v3/transactions/invoices`}</inlineCode>{`  `}</p>
    <h3 {...{
      "id": "medicare-claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medicare-claims",
        "aria-label": "medicare claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare claims`}</h3>
    <blockquote>
      <p parentName="blockquote">{`All fields are JSON strings unless otherwise noted.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type/Format`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Condition`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Example`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`funder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<strong parentName="td">{`medicare`}</strong>{` in lowercase`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` medicare`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`providerNumber `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 16 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Servicing provider number or other registration identifier which must be valid and active in Medipass for a given funder. For Medicare claims, a Medicare issued provider number should be used where available.`}<br />{` `}<strong parentName="td">{`Must be unique for an invoice`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  0065431A`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><inlineCode parentName="strong">{`patient`}</inlineCode></strong>{`  {}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`object`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highly recommended `}<br />{` A unique patient identifier - such as a UUID - generated by your system. Used for searching and reporting on transactions by patient in Medipass and for support purposes. Setting a unique refId per patient is highly recommended.If provided, future transactions can be processed using refId rather than providing patient and health fund account details. Any differing patient details for a given refId will update the patient record on Medipass.`}<strong parentName="td">{`A unique invoice can include only one patient.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 3DE2D2BC-17CC-4978-A79D-2151DC417B2F`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient first / given name as registered with Medicare. If only one name, set field to `}{`"`}{`Onlyname`}{`"`}{`. `}<br />{`A unique invoice can include only one patient. `}<br />{` • Can only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters `}<br />{` • Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value. `}<br />{`• At least one alpha or numeric character required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Mila`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Patient last/ family name as registered with Medicare.A unique invoice can include only one patient.`}<br />{` • Can only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters `}<br />{`• Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value.  `}<br />{` • At least one alpha or numeric character required. `}<br />{` • If patient has only one name, use `}{`"`}{`Onlyname`}{`"`}{` as placeholder in firstName field.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Harris`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.dobString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of birth in YYYY-MM-DD format `}<br />{`• must be a valid date `}<br />{`• must not be a date in the future `}<br />{`• must not be after each serviceDateFor Medicare patient claims, if patient is \\`}{`<`}{`15 years old as at each serviceDate, an adult =\\`}{`>`}{`18 years old claimant is required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 2010-09-01`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.mobile `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number required to send SMS claim and payment requests. Either domestic (04xxxxxxxx) or international (+614xxxxxxxx) format accepted. `}<br /><strong parentName="td">{`Only Australian domestic numbers supported.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  0411111111`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient.email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any valid email address. Used for receipts and statements.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  `}<a parentName="td" {...{
                "href": "mailto:mw@medipass.io"
              }}>{`mw@medipass.io`}</a>{` `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><inlineCode parentName="strong">{`healthFundAccount`}</inlineCode></strong>{`{}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`object for Medicare account details`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundAccount.healthFundCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<strong parentName="td">{`MDC`}</strong>{` for Medicare`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` MDC `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundAccount.membershipNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare card number, 10 digits `}<br /><strong parentName="td">{`The value must adhere to Medicare Card check digit routine.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 12345678`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundAccount.cardRank`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 or 2 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare: individual reference number, one digit of 1-9, as noted on physical card.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 1`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundAccount.sex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for Medicare patient checks, Medicare ECLIPSE claims. Patient sex, as one of:M: MaleF: FemaleO: OtherNull: Not stated/inadequately described (default value)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` F`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Medicare specific values`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Either: `}<br />{`• bulkbill: for bulk billed claims `}<br />{`• patientclaim: for patient claims `}<br /><strong parentName="td">{`A single claim/invoice can only be one of one type - mixed claim types are not permitted in a single invoice.`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` bulkbill`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.isInHospital`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hospital Indicator - whether or not the service(s) rendered to the patient was for treatment provided within a hospital facility. Valid values: true = In Hospital. Default is false.If Referral Override Code is set to H (Hospital) then Hospital Indicator must be set to true (In Hospital)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` true`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.lspNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric 1-999999`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location Specific Provider Number. Used by Medicare for diagnostic imaging and radiation oncology services. Can have leading zeros. `}<br />{`• Not valid for pathology providers. `}<br />{`• LSPN and Number of Patients Seen must not be set against the same service. `}<br />{`• LSPN and Time Duration must not be set against the same service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  01`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.facilityId `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Commonwealth Hospital Facility Provider Number. A unique identifier of a Registered Hospital or Day Care Facility. The value supplied must be in the format of Provider stem (6 digit number), 1 Practice Location character, 1 Check Digit (similar to medicare provider numbers). Must be filled with leading zeros if the provider number is greater than 2 characters but less than 8 characters. `}<br />{`• If isInHospital is set to true (In Hospital) then facilityId must be supplied.`}<br />{`• Hospital referrals usually require a FacilityId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`9988770W`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`For Bulk Bill claims only`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.benefitPayee.providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 8 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generally only valid for bulk billed claims, where the payee provider is different from the servicing provider. Benefits will be paid to the benefitProvider`}{`'`}{`s bank account. Note: this provider number must also be registered and active with Medipass. `}<br />{`• A unique invoice can include only one benefitPayee. `}<br />{`• Provider number must be registered with Medipass and enabled for Medicare.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  3452232B `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.benefitPayee.providerFullName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 10 alphanumeric characters `}{`&`}{` select special characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full name of provider including or excluding title. Will appear on assignment of benefit statement (DB4).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Dr. Jane Smith `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`For Patient Claims Only`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`if a separate claimant is specified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.firstName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant first / given name as registered with Medicare. If only one name, set field to `}{`"`}{`Onlyname`}{`"`}{`.`}<br />{`• A unique invoice can include only one claimant. `}<br />{`• Can only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters `}<br />{`• Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value. `}<br />{`• At least one alpha or numeric character required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Charles `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.lastName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant last / family name as registered with Medicare.`}<br />{`• A unique invoice can include only one claimant.`}<br />{`• Can only contain alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`) and hyphen (- ) characters`}<br />{`• Spaces must not appear before or after apostrophes, hyphens, other spaces or the supplied value.`}<br />{`• At least one alpha or numeric character required.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` James`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.dobString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claimant date of birth in YYYY-MM-DD format.`}<br />{`• Must be =\\`}{`>`}{` 18 years old as at date of each service `}<br />{`• Must be a valid date `}<br />{`• Must not be a date in the future `}<br />{`• Must not be after each serviceDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 1999-01-25`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobile number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile number required to send SMS claim and payment requests. In domestic or international format.Only domestic Australian numbers supported.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 0411111111`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.refId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique patient identifier generated by your system. If provided, future transactions can be processed using refId rather than providing patient and health fund account details. Any differing patient details for a given refId will update the patient record on Medipass.A unique invoice can include only one claimant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 3DE2D2BC-17CC-4978-A79D-2151DC417B2F`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.healthFundAccount.membershipNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare card number - 10 digits `}<br />{`• A unique invoice can include only one membershipNumber. `}<br />{`• value must adhere to Medicare Card check digit routine.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 1234567890`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.healthFundAccount.cardRank`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare individual reference number, one digit of 1-9, as noted on physical card.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`1`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.postalAddress.address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A valid street address.`}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0-9), space ( ), apostrophe (`}{`'`}{`), hyphen (-) and other special characters (/ , . : ; ) only.`}<br />{`• Spaces must not appear before or after other spaces or the supplied value. `}<br />{`• The value must contain at least one alpha or numeric character.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 4 Duke Street`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.postalAddress.addressLine2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Address line 2, typically unit or apartment or unit number.`}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0-9), space ( ), apostrophe (`}{`'`}{`), hyphen (-) and other special characters (/ , . : ; ) only.`}<br />{`• Spaces must not appear before or after other spaces or the supplied value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  Unit 827 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.postalAddress.city`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Also known as locality.`}<br />{`• Must be a valid city/locality as defined by Medicare `}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0-9), space ( ), apostrophe (`}{`'`}{`), hyphen (-) and other special characters (/ , . : ; ) only.`}<br />{`• Spaces must not appear before or after other spaces or the supplied value.`}<br />{`• The value must contain at least one alpha or numeric character.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Windsor`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.postalAddress.postcode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PostcodeMust be valid postcode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 3181`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.bankAccount.bankName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 30 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bank name is not processed by Medicare, but is displayed by Medipass in claim for validation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` NAB `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.bankAccount.accountName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 30 characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of account holder.`}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`), hyphen (-) and other special characters (.,/) only.`}<br />{`• Spaces must not appear before or after the supplied value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` Charles Willis `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.bankAccount.bsb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Six digit BSB number.Must be a valid BSB.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 023456 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimant.bankAccount.accountNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 9 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Account number.`}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0- 9), space ( ), apostrophe (`}{`'`}{`), hyphen (-) and other special characters (.,/) only.`}<br />{`• Spaces must not appear before or after the supplied value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 9988770W`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`For claims with referral requirements`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.issueDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of referral/request issuance in YYYY-MM-DD. Only used where referral is required.- Must be a valid date`}<br />{`• Must be greater than or equal to the Patient dob `}<br />{`• Must be present if Referral details supplied`}<br />{`• Must not be a date in the future`}<br />{`• Must not be after each serviceDate  `}<br />{`• Must be present for referred services`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 2020-07-23`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.periodType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Period type code as defined by Medicare and only used where referral used.Valid values: `}<br />{`• S: Standard – 12 months for GP or 3 months for Specialist referrals (Default value) `}<br />{`• N: Non-standard, if set must supply duration in serviceText field `}<br />{`• I: Indefinite`}<br />{`• Must be present if Referral details supplied, unless for Pathology or Diagnostic Imaging claims. `}<br />{`• If N or I, details are required in serviceText.`}<br />{`• Do not set for Pathology or Diagnostic Imaging claimsNote: referral period is not applicable for Pathology or Diagnostic Imaging referrals and should not be set for those claims.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` S`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.period`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 or 2 numeric`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For non-standard referral periods only, the number of months for referral validity. Numeric two digits.Note: not applicable for Pathology or Diagnostic Imaging referrals and should not be set for those claims.`}<br />{`• Must be present if Referral details supplied, unless for Pathology or Diagnostic Imaging claims.`}<br />{`• If N or I, details are required in serviceText. `}<br />{`• Do not set for Pathology or Diagnostic Imaging claims.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 10`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.providerNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 8 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Referring provider number. Note this must be a valid provider number, but the provider number does not need to be registered with Medipass.`}<br />{`• Must be present if Referral details supplied `}<br />{`• Must be a valid provider number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  4452232B`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.providerFullName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 30 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full name of referring provider, with or without title. Will appear on printed statements.Must be present if Referral details supplied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  Dr Emily Tay`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.referrerType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Either: `}<br />{`• GP: General Practitioner `}<br />{`• S: SpecialistMust be present if Referral details supplied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` GP `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.referral.referralTypeCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set as one of: `}<br />{`• D: Diagnostic Imaging, including Radiology Oncology `}<br />{`• P: Pathology `}<br />{`• S: Specialist, including Allied healthMust be present if Referral details supplied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  S `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.nonReferral.reason`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for exception claims normally requiring a referral. One of: `}<br />{`• E: Emergency `}<br />{`• H: Hospital in-patient referral (isInHospital should be Y for all services) `}<br />{`• L: Lost `}<br />{`• N: Not required, non-standard referral `}<br />{`• Only used where referral normally required and replaces referral details. `}<br />{`• If Referral Override Code is set to H (Hospital) then Hospital Indicator must be set to Y (In Hospital) and a facilityId should be set`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  H`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.nonReferral.description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 40 alpha characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of non-referral reason. Where: `}<br />{`• H: name of hospital `}<br />{`• L: supporting textMust be present if nonReferral details supplied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  Lost referral letter`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`For claimed service items`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems [] `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One or more claimItems must be specified for a claim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.accessionDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date for pathology test. Only for pathology services. The format is YYYY-MM-DD.`}<br />{`• Must not be equal to or less than service date `}<br />{`• Must not be prior to patient date of birth `}<br />{`• Must not be future dated  `}<br />{`• Value must be Australian time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  2021-02-21 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.accessionTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time for pathology test. Only for pathology services. The format is HH:MM`}<br />{`• Must not be equal to or less than service date`}<br />{`• Must not be prior to patient date of birth `}<br />{`• Must not be future dated `}<br />{`• Value must be Australian time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 17:30`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.accountReferenceNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1-9 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Account Reference (ACRF). A reference used by the claim submitter to identify a claim.`}<br />{`• The value supplied must be alpha (A-Z and a-z), numeric (0-9), space ( ), and special characters @ # $ % + = : ; , . - only. `}<br />{` • Spaces must not appear before or after other spaces or the supplied value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 19-1125B`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.afterCareOverride`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the service was performed as part of normal aftercare for the patient. Valid values: true = Not Normal Aftercare. Default is not set.Only applicable for General or Specialist Services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` true`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.collectionDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date the pathology sample was taken from patient. The format is YYYY-MM-DD.`}<br />{`• Must not be after accession date time`}<br />{`• If set, Accession date time must be set `}<br />{`• Must not be prior to patient date of birth`}<br />{`• Must not be prior to referral issue date `}<br />{`• Value must be Australian time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 2021-02-26`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.collectionTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time the pathology sample was taken from patient. The format is HH:MM.`}<br />{`• Must not be after accession date time`}<br />{`• If set, Accession date time must be set `}<br />{`• Must not be prior to patient date of birth`}<br />{`• Must not be prior to referral issue date `}<br />{`• Value must be Australian time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 17:30`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.duplicateServiceOverride`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether multiple services performed on the same day, by the same Service provider and should be treated as separate services. Valid values: true = duplicate service override. Default is not set.`}<br />{`• If Duplicate Service Override Indicator is set to true (Not Duplicate) then serviceTime or additional information in serviceText is required to support the reason for the override. `}<br />{`• Only applicable for General or Specialist Services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  true `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.duration `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time duration in minutes. In 15 minute intervals only.Only applicable for General or Specialist Services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  060`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.fieldQuantity `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 or 2 numbers, 1-99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of fields irradiated or the quantity of (15 minute) time blocks or derived fee intrathecal or epidural infusion services (e.g. items 18219 and 18227).`}<br />{`• Only applicable for General or Specialist Services. `}<br />{`• Field Quantity and Number of Patients Seen or Time Duration must not be set against the same service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  4 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.itemCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 5 numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any valid Medicare supported MBS code for a given date of service.Only valid MBS items for a given serviceDate and provider registered profession can be processed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  23`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.multipleProcedureOverride `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the multiple services rule must or must not be applied to the service being claimed. Valid values: true, Not Multiple. Default is not set.`}<br />{`• Only applicable for General or Specialist Services. `}<br />{`• If Multiple Procedure Override Indicator is set to true (Not Multiple) then must list serviceText providing the reason`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` true`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.numberOfPatientsSeen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 or 2 numbers, 1-99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of patients seen. Must be set for group attendance items (e.g. counseling) or visits (home, hospital or institution) to ensure the correct payment is made. `}<br />{`• Only applicable for General or Specialist Services.`}<br />{` • Number of Patients Seen and Time Duration must not be set against the same service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  2 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.patientContributionAmount `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency notation, up to 5 numeric and 2 decimal digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O^^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount paid by patient/claimant, if not full payment.For Patient claims, amounts less than price will invoke PDVC processing - Pay Doctor Via Cheque which will result in Servicing Australia issuing a physical cheque to present to the servicing provider. Set to $0 to indicate a fully unpaid patient claim item.The value supplied must be less than the chargeAmount.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 30.67`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.price`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currency notation, up to 5 numeric and 2 decimal digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Charge amount for the service. For bulk bill, this is normally the same as MBS benefit amount.Note that in bulk bill claims, Medicare will pay the MBS benefit amount which can be greater than the amount charged.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 50.67 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 128 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line item reference as nominated by you. Assists with reconciliation where multiple of the same item code and service date claimedThis reference should be unique within a given invoice.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 01 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.restrictiveOverride`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This code is used to allow payment for services where the account provides indication that the service is not restrictive with another service either within the same claim or on the patient history. Valid values: `}<br />{`• SP: Separate Sites `}<br />{`• NR: Not Related to consult `}<br />{`• NC: Not for ComparisonOnly applicable for General or Specialist Services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` SP `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.rule3ExemptIndicator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates if the pathology service is exempt from Rule 3 in the MBS. When set to true, the item is yes, exempt. Default is not set.`}<br />{`• Must only be set when provider is Pathology `}<br />{`• If Rule 3 Exempt Indicator is set, serviceTime must be set.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` true `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.s4b3ExemptIndicator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates if the pathology service is exempt from assessing in accordance with the S4b3 requirements in the MBS. When set to true, the item is yes, exempt. Default is not set.`}<br />{`• The value supplied must be set to true to indicate Pathology Service is exempt from S4b3 assessing requirements `}<br />{`• Must only be set when provider is Pathology `}<br />{`• If set, Hospital Indicator must be set to true`}<br />{`• Must not be set if Rule 3 Exempt Indicator is set`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` true`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.scpId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3-5 alphanumeric values`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Specimen Collection Point ID is used to identify the site where the pathology specimen was collected. The value supplied must be alpha (A-Z) or numeric (0- 9) and three to five characters long. Value must not be set to or equal zero. Leading zeros for values less than 5 characters, are acceptable e.g. 001, 0001 or 00001.`}<br />{`• `}<strong parentName="td">{`Specimen Collection Point Id (SCPId) must only be set when provider is Pathology`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 014`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.selfDeemedCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Valid values only`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A Self Deemed service is an additional service to a valid request. A substituted service is a service provided that has replaced the original service requested.Valid values:`}<br />{`• SD: Self Deemed`}<br />{`• SS: Substituted Service`}<strong parentName="td">{`Self Deemed Code must not be set when Referral Override Code is set`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` SD`}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.serviceDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YYYY-MM-DD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of service in YYYY-MM-DD format.`}<br />{`• Must be a valid date `}<br />{`• Must be within 2 years as at date of submission `}<br />{`• Must not be future dated `}<br />{`• Must not be before patient date of birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{`  2020-08-25 `}</Highlight></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.serviceText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Up to 50 alphanumeric characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Free text for additional information on claim assessment. Usually used for claims with exceptions - will be reviewed by Medicare staff.`}<br />{`• The value supplied must be alpha (A-Z and a- z), numeric (0-9), space ( ), and special characters @ # $ % + = : ; , . -.`}<br />{`• Spaces must not appear before or after other spaces or the supplied value.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare.claimItems.serviceTime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HH:MM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time of service in 24 hour format: HH:MM as in Australian time zone. Used if multiple of same items claimed on same day, by same provider, for same patient.The time supplied must reflect the time zone in Australia, the transaction was created in.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><Highlight backgroundColour="grey" textColour="dark" mdxType="Highlight">{` 14:25`}</Highlight></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      